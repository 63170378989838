body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Josefin Sans",
  sans-serif;
  font-family: 'Ubuntu', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  width: '100vw';
}

html, body {
  height: 100%;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.multine-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
}

.singleline-ellipsis {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-word;
}

.lime-clamp1 {
  -webkit-line-clamp: 1;
}

/* Hide HTML5 Up and Down arrows. */
input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}



::-webkit-scrollbar {
  /* -webkit-appearance: none; */
  border-radius: 20px;
  width: 10px;
}

/* *:hover::-webkit-scrollbar {
    width: 10px;
    height: 10px;
} */

/* Track */
::-webkit-scrollbar-track {
  background: lightgrey;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
  box-shadow: inset 0 0 2px grey;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: grey;
  -webkit-box-shadow: 0 0 1px rgba(255,255,255, 0);
  border-radius: 20px;
  /* border: 2px solid lightgrey; */
}
/* #f18d03; */

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: #212121; */
  cursor: pointer;
  width: 10px;
  /* border: 2px solid lightgrey; */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.noScrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
.noScrollbar {
  -ms-overflow-style: none;
}

.textCapitalize {
  text-transform: capitalize !important;
}

.MuiTypography-root {
  word-break: break-word;
}
